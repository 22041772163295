
import Api from "@/services/Api";
import { defineComponent, Ref, ref, onMounted, watch } from "vue";
import type { PropType } from "vue";
import useAlert from '@/composables/Alert'
import useEmitter from "@/composables/Emmiter";
import Button from "@/components/Button.vue"



export default defineComponent({
  name: "ModalProjectsList",
  components: {
    Button,
  },
  props: {
    project: Object as PropType<any>,
    fechaModal: Function,
    abreModal: Function,
  },

  setup(props) {

    const { showTimeAlert } = useAlert()
    const emitter = useEmitter()

    const project: Ref<any> = ref({});
    const arrayProjects: Ref<any[]> = ref([]);
    const usuarioLojas: Ref<any> = ref([]);

    const loadSave: Ref<boolean> = ref(false);
    const loadExcluir: Ref<boolean> = ref(false);
    const loadRemoveUsuario: Ref<boolean> = ref(false);
    const loadResetarSenha: Ref<boolean> = ref(false);
    const dialogVisible: Ref<boolean> = ref(false);

    const clikouResetarSenha: Ref<boolean> = ref(false);
    const novaSenha: Ref<string> = ref('indicar');


    const inputComMascara: Ref<string> = ref('');
    const adicionarUsuarioEmNovaLoja: Ref<boolean> = ref(false)

    const arrayCargos: Ref<any[]> = ref([]);
    const idLoja: Ref<any> = ref(null);



    // ---------------------------------
    function formatarInput(type: string, event) {

      if (type === 'documento') {
        inputComMascara.value = event.replace(/\D/g, "");
        inputComMascara.value = inputComMascara.value.replace(/(\d{3})(\d)/, "$1.$2");
        inputComMascara.value = inputComMascara.value.replace(/(\d{3})(\d)/, "$1.$2");
        inputComMascara.value = inputComMascara.value.replace(/(\d{3})(\d{1,2})$/, "$1-$2");

        project.value.documento = inputComMascara.value;
      }

      if (type === 'telefone') {
        inputComMascara.value = event.replace(/\D/g, "");
        inputComMascara.value = inputComMascara.value.replace(/^(\d\d)(\d)/g, "($1) $2");
        inputComMascara.value = inputComMascara.value.replace(/(\d{5})(\d)/, "$1-$2");

        project.value.telefone = inputComMascara.value;
      }

      return ''

    }
    // ---------------------------------

    function fechaModal(){

      project.value = {};
      props.fechaModal && props.fechaModal()

    }

    function abreModal(){

      project.value = { ...props.project } 
    
      // getLojasUsuario()

    }

    function enviaUsuarioAtualizado(usuarioParam) {
      emitter.emit('enviaUsuarioAtualizado', { usuario: usuarioParam })
    }

    function removeUsuarioLista(usuarioParam) {
      emitter.emit('removeUsuarioLista', { usuario: usuarioParam })
    }


    //     FUNÇÕES PRINCIPAIS     //
    // 
    //      ||            ||      //
    //      ||            ||      //
    //      ||            ||      //
    //      ||            ||      //
    //     \  /          \  /     //
    //      \/            \/      //


    async function resetarSenha() { 

      loadResetarSenha.value = true

      const { data } = await Api.put("changePassword", {...project.value, password: novaSenha.value })

      loadResetarSenha.value = false

      if(data.error) return showTimeAlert(data?.message, "error")

      novaSenha.value = ""

      return showTimeAlert('Senha resetada com sucesso!', "success")
      
    }

    async function deletarUsuario() { 

      loadExcluir.value = true

      const { data } = await Api.delete("deleteUser", { id: project.value.id })

      loadExcluir.value = false

      if(data.error) return showTimeAlert(data?.message, "error")

      removeUsuarioLista(data)

      dialogVisible.value = false;
      fechaModal()

      return showTimeAlert('usuário deletado com sucesso!', "success")
      
    }

    async function salvaProject() {

      if(!project.value.name) return showTimeAlert('Verifique os dados e tente novamente', "error");
       
      loadSave.value = true
      const { data } = await Api.post("createProjects", { ...project.value});

      loadSave.value = false

      if(data.error) return showTimeAlert('Verifique os dados e tente novamente', "error");

      enviaUsuarioAtualizado(data)

      fechaModal()

      return showTimeAlert('usuário salvo com sucesso!', "success")

    }

    async function getProjects() {

      const {data} = await Api.get("projects");

      arrayProjects.value = data;

    }

    async function getCargo() {

      const {data} = await Api.get('getCargosUsuario');
      arrayCargos.value = data;
    }

    
    async function getLojasUsuario(){

      const { data } = await Api.get('buscaLojasUsuario', {idUsuario: project.value.id})

      usuarioLojas.value = data.lojas.map(({id})=>id)

    }

    async function removeLojaUsuario() {

      project.value = true

      // const { data } = await Api.post("removeLojaUsuario", { idUsuario: project.value.id, arrayIdLoja: [idLoja.value] })

      project.value = false

      // if(!data) return showTimeAlert(data.message, "error");
      
      showTimeAlert('usuário removido com sucesso!', "success");

      removeUsuarioLista('example')

      dialogVisible.value = false;
      fechaModal()

    }
    
    emitter.on("idLojaUser", async params => {
       idLoja.value = params.idLoja;
    });


    // abrindo modal com usuario
    watch(() => props.project, async () => { abreModal() }, { deep: true })

    onMounted(async()=>{
      getCargo()
      getProjects()
    })
   
    return {
      project,
      fechaModal,
      deletarUsuario,
      salvaProject,
      resetarSenha,
      formatarInput,
      loadSave,
      loadRemoveUsuario,
      arrayCargos,
      loadExcluir,
      arrayProjects,
      adicionarUsuarioEmNovaLoja,
      clikouResetarSenha,
      novaSenha,
      loadResetarSenha,
      usuarioLojas,
      idLoja,
      removeLojaUsuario,
      dialogVisible
    };
  },
});
