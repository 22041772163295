
import {
  defineComponent,
  Ref,
  ref,
  onMounted,
watch,
} from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import ModalProjectsList from "./components/ModalProjectsList.vue";
import Title from "@/components/Title.vue"
import Button from "@/components/Button.vue"
import { useRouter,useRoute, LocationQueryValue } from "vue-router";
import { Modal } from "bootstrap";
import Api from "@/services/Api";
import useEmitter from "@/composables/Emmiter";
import useAlert from '@/composables/Alert'

interface Input {
  cargo: String,
  data: String
}

interface Project {
  id: string | number | null;
  name: string | number | null;
  description: string | number | null;
  url: string | number | null;
  created_at: string | number | null;
  updated_at: string | number | null;
}



export default defineComponent({
  name: "ProjectsList",

  components: {
    Field,
    Form,
    ErrorMessage,
    ModalProjectsList,
    Title,
    Button
  },

  setup() {
    const router = useRouter();

    const route = useRoute();
    const emitter = useEmitter();
    const { showTimeAlert } = useAlert()
    
    const dialogVisible: Ref<boolean> = ref(false);

    const pagina: Ref<number> = ref(1);
    const limite: Ref<number> = ref(20);

    const projectsArray: Ref<Project[]> = ref([]);

    const projectModal: Ref<any> = ref({})
    const modalUsuario: Ref<any> = ref()
    const idProject: Ref<any> = ref(null)

    //refs recebidos do FilterUser
    const input: Ref<Input> = ref({
      cargo: "",
      data: ""
    });

    const idLoja: Ref<LocationQueryValue[] | string | null> = ref(null)
    const lojaFixada: Ref<any> = ref(null)
    const fastFind: Ref<string> = ref('');


    function openModal(usuario?) {
      // pegarLojaId()
      projectModal.value = usuario || {}
      modalUsuario.value.show()

    }

    async function fechaModal() {
      
      modalUsuario.value.hide()

    }


    async function buscaUsuario(paginaParam: number,  search: boolean = false){

      const { data } = await Api.get("buscaUsuarioPaginado", { pagina: paginaParam, limite: limite.value, idLoja: idLoja.value, cargo: input.value.cargo, data: input.value.data , input: fastFind.value} )
      
      //quando eu chamo a busca de usuario pelo modal de filtro ao invez de adicionar quero que substitua o valor da array
      if(!search) projectsArray.value = [...projectsArray.value, ...data.usuarios]
      else projectsArray.value = data.usuarios

    }

    async function getAllProjects(){
      try {
        const {data} = await Api.get('projects');
        projectsArray.value = data;
      } catch (error) {
        console.log("error")
      }
    }

    async function deletarProjeto(id: any){
      const {data} = await Api.delete('deleteProjects', {id: id});
      if(data.error) return showTimeAlert('Tente novamente', "error")
      getAllProjects();
      dialogVisible.value = false
      return showTimeAlert('Projeto deletado com sucesso!', "success")
    }


    emitter.on("FilterUser", (valor: any) => {
      if(valor.input) input.value = valor.input;

      pagina.value = 1

      buscaUsuario(pagina.value,true)

    })

    emitter.on("enviaUsuarioAtualizado", (valor: any) => {

      getAllProjects()
      // const index = projectsArray.value.findIndex(({id}) => id === valor.usuario.id)

      // if(index !== -1) projectsArray.value[index] = { ...valor.usuario }
      // else projectsArray.value.unshift({ ...valor.usuario })

    })

    emitter.on("removeUsuarioLista", (valor: any) => {

      getAllProjects()
      // const index = projectsArray.value.findIndex(({id}) => id === valor.usuario.id)

      // if(index !== -1) projectsArray.value.splice(index,1)

    })

    function getLocalStorage(){
      lojaFixada.value = JSON.parse(localStorage.getItem('lojaFixada') as any)      
      if(lojaFixada.value){
        idLoja.value = lojaFixada.value && lojaFixada.value.id
      }
    }

    
    
    //----------- colocar o filtro fazendo requisição
    function buscar(){

      if(fastFind.value.length > 2 || !fastFind.value){
          pagina.value = 1
          buscaUsuario(pagina.value, true)
        }
        
     }


    onMounted(async () => {
      getAllProjects()
      modalUsuario.value = new Modal(document.querySelector(`#ModalProjectsList`));
    })

    

    return {
      projectsArray,
      limite,
      history,
      openModal,
      fechaModal,
      router,
      pagina,
      buscaUsuario,
      projectModal,
      idLoja,
      lojaFixada,
      fastFind,
      buscar,
      dialogVisible,
      deletarProjeto,
      idProject
    };
  },
});
